import {multiply, inv} from 'mathjs'
//This is a code that has the function to analyse the main types of kinematics pairs.

export function radianToDegree(numberInDegree:number):number{
	return numberInDegree*180/Math.PI
}

export function degreeToRadian(numberInRadian:number):number{
	return numberInRadian*Math.PI/180
}


export function RR_Fwd(c:number, b:number, Xq:number, Yq:number, PHI:number, PSI:number, PHIRad_d:number, PSIRad_d:number, PHIRad_dd:number, PSIRad_dd:number):number[] {
	// CHANGING TO RADIANS
	let PHIRad:number = degreeToRadian(PHI)
	let PSIRad:number = degreeToRadian(PSI)

	// FORWARD KINEMATICS - DISPLACEMENT 
	let X = c*Math.cos(PHIRad) + b*Math.cos(PSIRad)
	let Y = c*Math.sin(PHIRad) + b*Math.sin(PSIRad)
	let Xa = X + Xq	
	let Ya = Y + Yq

	// FORWARD KINEMATICS - VELOCITY
	let Xa_d = - c*PHIRad_d*Math.sin(PHIRad) - b*PSIRad_d*Math.sin(PSIRad)
	let Ya_d = + c*PHIRad_d*Math.cos(PHIRad) + b*PSIRad_d*Math.cos(PSIRad)

	// FORWARD KINEMATICS - ACCELERATION
	let Xa_dd = - c*PHIRad_dd*Math.sin(PHIRad) - b*PSIRad_dd*Math.sin(PSIRad) - c*Math.cos(PHIRad)*PHIRad_d**2 - b*Math.cos(PSIRad)*PSIRad_d**2
	let Ya_dd = c*PHIRad_dd*Math.cos(PHIRad) + b*PSIRad_dd*Math.cos(PSIRad) - c*Math.sin(PHIRad)*PHIRad_d**2 - b*Math.sin(PSIRad)*PSIRad_d**2

	return [ Xa, Ya, Xa_d, Ya_d, Xa_dd, Ya_dd ]
}

export function RR_Inv(c:number, b:number, Xa:number, Ya:number, Xq:number, Yq:number, Xa_d:number, Ya_d:number, Xa_dd:number, Ya_dd:number, I:boolean):number[]{
	//INVERSE KINEMATICS (POSITION CALCULATION)
	let X:number = Xa - Xq
	let Y:number = Ya - Yq

	let A:number = X**2 + Y**2 + c**2 - b**2 + 2*c*X
	let B:number = - 2*c*Y
	let C:number = X**2 + Y**2 + c**2 - b**2 - 2*c*X
	
	let t1:number = (- B + Math.sqrt(B**2 - A*C))/A
	let t2:number = (- B - Math.sqrt(B**2 - A*C))/A

	if(I===true){
		var t:number = t1
	}else{
		var t:number = t2
	}

	let PHIRad:number = 2*Math.atan(t)
	let PHI:number = radianToDegree(PHIRad)

	let U:number = (X - c*Math.cos(PHIRad))/b
	let V:number = (Y - c*Math.sin(PHIRad))/b	

	let PSIRad:number = Math.atan2(V,U)
	let PSI:number = radianToDegree(PSIRad)

	let Xb = Xq + c*Math.cos(PHIRad)
	let Yb = Yq + c*Math.sin(PHIRad)
		
	// INVERSE KINEMATICS (VELOCITY CALCULATION)
	let X_d:number = Xa_d
	let Y_d:number = Ya_d

    let D:number[][] = [
                [- c* Math.sin(PHIRad),	- b*Math.sin(PSIRad)],
			    [c*Math.cos(PHIRad), 		b*Math.cos(PSIRad)]
            ]	
    
	let	E:number[][] = [
				[X_d],
				[Y_d]
			]

	let D_inv:number[][] = inv(D)
	let F:number[][] = multiply(D_inv,E) 
    
	let PHIRad_d:number = F[0][0]
	let PSIRad_d:number = F[1][0]
	
	let Xb_d = - c*PHIRad_d*Math.sin(PHIRad)
	let Yb_d = + c*PHIRad_d*Math.cos(PHIRad)

	//INVERSE KINEMATICS (ACCELERATION CALCULATION)
	let X_dd:number = Xa_dd
	let Y_dd:number = Ya_dd

	let G:number[][] = [
			[-c*Math.sin(PHIRad), -b*Math.sin(PSIRad)], 
			[ c*Math.cos(PHIRad),  b*Math.cos(PSIRad)]
		]

	let H:number[][] = [
			[X_dd + c*Math.cos(PHIRad)*PHIRad_d**2 + b*Math.cos(PSIRad)*PSIRad_d**2],
			[Y_dd + c*Math.sin(PHIRad)*PHIRad_d**2 + b*Math.sin(PSIRad)*PSIRad_d**2]

		]

	let G_inv:number[][] = inv(G)
	let L:number[][] = multiply(G_inv,H)

	let PHIRad_dd = L[0][0]
	let PSIRad_dd = L[1][0]

	let Xb_dd = - c*PHIRad_dd*Math.sin(PHIRad) - c*Math.cos(PHIRad)*PHIRad_d**2
	let Yb_dd =  c*PHIRad_dd*Math.cos(PHIRad) - c*Math.sin(PHIRad)*PHIRad_d**2

	return [Xb, Yb, Xb_d, Yb_d, Xb_dd, Yb_dd, PHI, PSI, PHIRad_d, PSIRad_d, PHIRad_dd, PSIRad_dd]
}

export function RP_Fwd(a:number, Xq:number, Yq:number, r:number, PHI:number, r_d:number, PHI_d:number, r_dd:number, PHI_dd:number):number[]{
	
	//FORWARD KINEMATICS (POSITION CALCULATION)
	let X:number = -a*Math.sin(PHI) + r*Math.cos(PHI)
	let Y:number = r*Math.sin(PHI) + a*Math.cos(PHI)
	
	let Xa:number = Xq + X
	let Ya:number = Yq + Y

	// FORWARD KINEMATICS (VELOCITY CALCULATION)
	let Xa_d:number = ( - a*Math.cos(PHI) - r*Math.sin(PHI))*PHI_d + Math.cos(PHI)*r_d
	let Ya_d:number = (r*Math.cos(PHI) - a*Math.sin(PHI))*PHI_d + Math.sin(PHI)*r_d

	// FORWARD KINEMATICS (ACCELERATION CALCULATION)
	let Xa_dd:number = ( - a*Math.cos(PHI) - r*Math.sin(PHI))*PHI_dd + Math.cos(PHI)*r_dd + (a*PHI_d*Math.sin(PHI) - r*PHI_d*Math.cos(PHI) - r_d*Math.sin(PHI)) * PHI_d + ( - PHI_d*Math.sin(PHI))*r_d
	let Ya_dd:number = (r*Math.cos(PHI) - a*Math.sin(PHI))*PHI_dd + Math.sin(PHI)*r_dd + (- a*PHI_d*Math.cos(PHI) - r*PHI_d*Math.sin(PHI) + r_d*Math.cos(PHI))*PHI_d + (PHI_d*Math.cos(PHI))*r_d

	return [Xa, Ya, Xa_d, Ya_d, Xa_dd, Ya_dd]
}

 export function PR_Inv(c:number, b:number, Xa:number, Ya:number, Xa_d:number, Ya_d:number, Xa_dd:number, Ya_dd:number, I:boolean)
{
	//CINEMATICA INVERSA - POSICOES
	let PHIRad_1:number = Math.asin((Ya - c)/b)
	let PHIRad_2:number = Math.asin(- (Ya - c)/b) + Math.PI

	if (I === true){
		var PHIRad:number = PHIRad_1 
	} else { //SuggestedI == 2
		var PHIRad:number = PHIRad_2;
	}
	
	
	let	S:number = Xa - b * Math.cos(PHIRad);
	let Xb = S //+ c*Math.cos(Math.PI/2)// + c*Math.cos(Math.PI/2);
	let Yb = c //*Math.sin(Math.PI/2);
	let PHI = radianToDegree(PHIRad);
	
	//CINEMATICA INVERSA - VELOCIDADES
	let PHIRad_d = Ya_d/(b*Math.cos(PHIRad));
	let S_d = Xa_d + b*PHIRad_d*Math.sin(PHIRad);

	//CINEMATICA INVERSA - ACELERACOES
	let PHIRad_dd = (b*PHIRad_d**2*Math.sin(PHIRad) + Ya_dd)/(b*Math.cos(PHIRad));

	let S_dd = Xa_dd + b*PHIRad_dd*Math.sin(PHIRad) + b*PHIRad_d**2*Math.cos(PHIRad);
	
	

	return [Xb, Yb, S, PHI, S_d, PHIRad_d, S_dd, PHIRad_dd];
}

export function RP_Inv(a:number, Xq:number, Yq:number, Xa:number,Ya:number,Xa_d:number,Ya_d:number,Xa_dd:number,Ya_dd:number, I:number)
{
	//CINEMATICA INVERSA - POSICOES
	let X = Xa - Xq;
	let Y = Ya - Yq;

	let X_d = Xa_d;
	let Y_d = Ya_d;
	
	let X_dd = Xa_dd;
	let Y_dd = Ya_dd;
	
	let r:number = 0;
	
	if (I == 1) {
		r = Math.sqrt( X**2 + Y**2 - a**2);
	} else {// suggested I ==2
		r = - Math.sqrt( X**2 + Y**2 - a**2);
	}

	let V = - (a * X - r * Y) / ( a**2 + r**2);
	let U =  (a * Y + r * X) / ( a**2 + r**2);

	let PHIRad = Math.atan2(V, U);

	//CINEMATICA INVERSA - VELOCIDADES
	let PHIRad_d = - (X_d * Math.sin(PHIRad) - Y_d * Math.cos(PHIRad))/r;
	let r_d = (X_d * (r * Math.cos(PHIRad) - a * Math.sin(PHIRad)) + Y_d * (a * Math.cos(PHIRad) + r * Math.sin(PHIRad))) / r;

	//CINEMATICA INVERSA - ACELERACOES
	let matrix_G = ([[ -a * Math.cos(PHIRad) - r * Math.sin(PHIRad), Math.cos(PHIRad)],
					[-a * Math.sin(PHIRad) + r * Math.cos(PHIRad), Math.sin(PHIRad)]]);
	
	let matrix_G_inv = inv(matrix_G);
	
	let matrix_H = ([[ X_dd - (a * Math.sin(PHIRad) * PHIRad_d**2 - r * PHIRad_d**2 * Math.cos(PHIRad) - r_d * Math.sin(PHIRad) * PHIRad_d - PHIRad_d * Math.sin(PHIRad) * r_d)],
					 [Y_dd - (-a * Math.cos(PHIRad) * PHIRad_d**2 - r * PHIRad_d**2 * Math.sin(PHIRad) + r_d * Math.cos(PHIRad) * PHIRad_d + PHIRad_d * Math.cos(PHIRad) * r_d) ]]);
	  
	let matrix_I:number[][] = multiply(matrix_G_inv, matrix_H);

	let PHIRad_dd = matrix_I[0][0];
	let r_dd = matrix_I[1][0];
	
	let PHI = radianToDegree(PHIRad);
	
	return [r, PHI, r_d, PHIRad_d, r_dd, PHIRad_dd]

}

