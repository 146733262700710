import React from 'react'
import './Home.css'
import logo from '../img/logo.svg'

function Home () {
  return (
    <div className="Home">
      <div className="Logo">
        <img src={logo} alt=""/>
        <h1>CORPORIGIDO.<span className="Red">X</span><span className="Green">Y</span><span className="Blue">Z</span></h1>
        <h2>Uma maneira simples de analisar cinemática e dinâmica de mecanismos!</h2>
        <h3>Essa versão é para teste das novas funcionalidades do site!</h3>
      </div>
    </div>
  )
}

export default Home
