import React from 'react'

import {Scatter} from 'react-chartjs-2'



interface IPoint{ 
  x:number, 
  y:number,
}

interface IDataProps{
  label?:string;
  pointLabels?:Array<string>;
  data: Array<IPoint>;
  borderColor?: string 
}
  

function MechanismGraph(props:IDataProps){
    return(
        <div style={{height:'500px', width:'500px' }}>
            <Scatter 
                data={{
                        labels:props.pointLabels || ' ',
                        datasets: [
                          {
                            label: props.label || 'Chart title',
                            fill: false,
                            lineTension: 0,
                            borderColor: props.borderColor || 'rgba(75,192,192,1)',
                            showLine: true,
                            data:props.data
                          }
                        ]
                      }}
                options={
                  {
                    tooltips:{
                      callbacks:{
                        label: function(tooltipItem:any, data:any){
                          var label = data.labels[tooltipItem.index]
                          return label + ': (' + tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ')'
                        }
                      }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                min: -10,
                                max: +10,
                                stepSize:1,
                            }
                        }],
                        xAxes:[{
                          ticks:{
                            min: -10,
                            max: +10,
                            stepSize: 1,
                          }
                        }]
                    }
                  }
                }
            />
        </div>
    )
}

export default MechanismGraph