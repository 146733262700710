import React, { useState } from 'react'

import MechanismGraph from '../MechanismGraph'
import {RR_Fwd, PR_Inv} from './kin-pair'



function SliderCrankLinkageCalc(){
    const [theta2, setTheta2] = useState<number>(45)
    const [theta2Rad_d, setTheta2Rad_d] = useState<number>(10)
    const [theta2Rad_dd, setTheta2Rad_dd] = useState<number>(0)
    const [linkA, setLinkA] = useState<number>(1.4)
    const [linkB, setLinkB] = useState<number>(4)
    const [crossLinkage, setCrossLinkage] = useState<boolean>(false)
    const [sliderOffset, setSliderOffset] = useState<number>(1)

    let X_O2:number=0 
    let Y_O2:number=0 

    //KINEMATICS CALCULATION
    let kinpar1:number[] = RR_Fwd(0,linkA, X_O2, Y_O2,0,theta2,0,theta2Rad_d,0,theta2Rad_dd)
    let Xa:number = kinpar1[0]
    let Ya:number = kinpar1[1]
    let Xa_d:number = kinpar1[2]
    let Ya_d:number = kinpar1[3]
    let Xa_dd:number = kinpar1[4]
    let Ya_dd:number = kinpar1[5]
    
    let kinpar2:number[] = PR_Inv(sliderOffset, linkB, Xa, Ya, kinpar1[2], kinpar1[3], kinpar1[4], kinpar1[5], crossLinkage)
    let Xb:number = kinpar2[0]
    let Yb:number = kinpar2[1]
    let S:number = kinpar2[2]
    let theta3:number = kinpar2[3]
    let S_d:number = kinpar2[4]
    let theta3Rad_d:number = kinpar2[5]
    let S_dd:number = kinpar2[6]
    let theta3Rad_dd:number = kinpar2[7]
    

    return(
        <div className="container">
            <div>
                <h1>modelagem Mecanismo Biela-Manivela Pistão</h1>
            </div>
            <div className='row'>
                <div className="col-lg-3 col-sm-12">
                    <div className="shadow p-3 mb-5 bg-body rounded h-100">
                        <h2>inputs</h2>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Variáveis</button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <label className="form-control-label">Theta2: </label>
                                        <input type="number" className="form-control" value={theta2} onChange={(e)=>setTheta2(parseInt(e.target.value))}/>

                                        <label className="form-control-label">Theta2_d: </label>
                                        <input type="number" className="form-control" value={theta2Rad_d} onChange={(e)=>setTheta2Rad_d(parseInt(e.target.value))}/>


                                        <label className="form-control-label">Theta2_dd: </label>
                                        <input type="number" className="form-control" value={theta2Rad_dd} onChange={(e)=>setTheta2Rad_dd(parseInt(e.target.value))}/>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">Constantes</button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div>
                                            <label className="form-control-label">Link A:</label>
                                            <input type="number" className="form-control" value={linkA} onChange={(e)=>setLinkA(parseInt(e.target.value))} />
                                        </div>
                                        <div>
                                            <label className="form-control-label">Link B: </label>
                                            <input type="number" className="form-control" value={linkB} onChange={(e)=>setLinkB(parseInt(e.target.value))}/>
                                        </div>
                                        <div>
                                            <label className="form-control-label">Distância do pistão: </label>
                                            <input type="number" className="form-control" value={sliderOffset} onChange={(e)=>setSliderOffset(parseInt(e.target.value))}/>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="form-check-input" id="flexCheckDefault" onChange={(e)=>setCrossLinkage(e.target.checked)}/>
                                            <label className="form-check-label" htmlFor="flexCheckDefault"> Mecanismo cruzado</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <div className="shadow p-3 mb-5 bg-body rounded center h-100">
                        <MechanismGraph 
                            label='Biela-manivela pistão'
                            pointLabels={['O2', 'A', 'S', ' ']}
                            data={[
                                { x:X_O2, y:Y_O2 },
                                { x:Xa,y:Ya },
                                { x:Xb,y:Yb },
                                { x:kinpar2[0] + 0.5, y:kinpar2[1] }
                            ]}
                            borderColor='rgb(0, 128, 0)'
                            />            
                    </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                    <div className="shadow p-3 mb-5 bg-body rounded h-100">
                        <h2>outputs</h2>
                        <div>
                            <span>JuntaA = X:{Xa.toFixed(2)}, Y:{Ya.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaA_d = X:{Xa_d.toFixed(2)}, Y:{Ya_d.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaA_dd = X:{Xa_dd.toFixed(2)}, Y:{Ya_dd.toFixed(2)}</span>
                        </div>
                        <div>
                            <span> Theta3: {theta3.toFixed(1)}</span>
                        </div>
                        <div>
                            <span> Theta3_d: {theta3Rad_d.toFixed(1)}</span>
                        </div>
                        <div>
                            <span> Theta3_dd: {theta3Rad_dd.toFixed(1)}</span>
                        </div>
                        <div>
                            <span> Slide: {S.toFixed(1)}</span>
                        </div>
                        <div>
                            <span>Slide_d = {S_d.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>Slide_dd = {S_dd.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaB = X:{Xb.toFixed(2)}, Y:{Yb.toFixed(2)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SliderCrankLinkageCalc