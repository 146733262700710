import React, { useState } from 'react'

import {RR_Fwd, RR_Inv} from './kin-pair'
import MechanismGraph from '../MechanismGraph';



export default function WattsSixBarLinkageCalc(){
    const [theta2, setTheta2] = useState<number>(10)
    const [theta2Rad_d, setTheta2Rad_d] = useState<number>(0)
    const [theta2Rad_dd, setTheta2Rad_dd] = useState<number>(0)
    const [linkO2A, setLinkO2A] = useState<number>(6)
    const [linkO2C, setLinkO2C] = useState<number>(2)
    const [gamma2, setGamma2] = useState<number>(10)
    const [linkAB, setLinkAB] = useState<number>(3)
    const [linkO3B, setLinkO3B] = useState<number>(4)
    const [linkCD, setLinkCD] = useState<number>(3)
    const [linkO4D, setLinkO4D] = useState<number>(3)
    const [crossLinkage, setCrossLinkage] = useState<boolean>(true)

    let X_O2:number=0 
    let X_O3:number=1
    let X_O4:number=0.5 
    let Y_O2:number=0 
    let Y_O3:number=2 
    let Y_O4:number=5 

    //KINEMATICS CALCULATION
    let kinparO2A:number[] = RR_Fwd(0,linkO2A, X_O2, Y_O2,0,theta2,0,theta2Rad_d,0,theta2Rad_dd)
    let Xa:number = kinparO2A[0]
    let Ya:number = kinparO2A[1]
    let Xa_d:number = kinparO2A[2]
    let Ya_d:number = kinparO2A[3]
    let Xa_dd:number = kinparO2A[4]
    let Ya_dd:number = kinparO2A[5]

    let kinparO2C:number[] = RR_Fwd(0,linkO2C, X_O2, Y_O2,0,theta2 + gamma2,0,theta2Rad_d,0,theta2Rad_dd)
    let Xc:number = kinparO2C[0]
    let Yc:number = kinparO2C[1]
    let Xc_d:number = kinparO2C[2]
    let Yc_d:number = kinparO2C[3]
    let Xc_dd:number = kinparO2C[4]
    let Yc_dd:number = kinparO2C[5]

    let kinparO3BA:number[]=RR_Inv(linkO3B,linkAB,Xa,Ya,X_O3,Y_O3,Xa_d,Ya_d,Xa_dd,Ya_dd,crossLinkage)
    let Xb:number = kinparO3BA[0]
    let Yb:number = kinparO3BA[1]
    let Xb_d:number = kinparO3BA[2]
    let Yb_d:number = kinparO3BA[3]
    let Xb_dd:number = kinparO3BA[4]
    let Yb_dd:number = kinparO3BA[5]
    let theta3:number = 180 + kinparO3BA[6]
    let theta4:number = kinparO3BA[7]

    let kinparO4DC:number[]=RR_Inv(linkO4D,linkCD,Xc,Yc,X_O4,Y_O4,Xc_d,Yc_d,Xc_dd,Yc_dd,crossLinkage)
    let Xd:number = kinparO4DC[0]
    let Yd:number = kinparO4DC[1]
    let Xd_d:number = kinparO4DC[2]
    let Yd_d:number = kinparO4DC[3]
    let Xd_dd:number = kinparO4DC[4]
    let Yd_dd:number = kinparO4DC[5]
    let theta5:number = 180 + kinparO4DC[6]
    let theta6:number = kinparO4DC[7]

    return(
        <div className="container">
            <div>
                <h1>modelagem Mecanismo Watts 6 barras</h1>
            </div>
            <div className="row">
                <div className="col-lg-3 col-sm-12">
                    <div className="shadow p-3 mb-5 bg-body rounded h-100">
                        <h2>inputs</h2>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Variáveis</button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <label className="form-control-label">Theta2: </label>
                                        <input type="number" className="form-control" value={theta2} onChange={(e)=>setTheta2(parseInt(e.target.value))}/>

                                        <label className="form-control-label">Theta2_d: </label>
                                        <input type="number" className="form-control" value={theta2Rad_d} onChange={(e)=>setTheta2Rad_d(parseInt(e.target.value))}/>


                                        <label className="form-control-label">Theta2_dd: </label>
                                        <input type="number" className="form-control" value={theta2Rad_dd} onChange={(e)=>setTheta2Rad_dd(parseInt(e.target.value))}/>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Constantes</button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <label className="form-control-label"> Link O2A: </label>
                                        <input type="number" className="form-control" value={linkO2A}  onChange={(e)=>setLinkO2A(parseInt(e.target.value))}/>

                                        <label className="form-control-label"> Link O2C: </label>
                                        <input type="number" className="form-control" value={linkO2C} onChange={(e)=>setLinkO2C(parseInt(e.target.value))}/>

                                        <label className="form-control-label">Ângulo Gamma2: </label>
                                        <input type="number" className="form-control" value={gamma2} onChange={(e)=>setGamma2(parseInt(e.target.value))}/>
                                        
                                        <label className="form-control-label"> Link AB: </label>
                                        <input type="number" className="form-control" value={linkAB} onChange={(e)=>setLinkAB(parseInt(e.target.value))}/>
                                        
                                        <label className="form-control-label"> Link O3B: </label>
                                        <input type="number" className="form-control" value={linkO3B} onChange={(e)=>setLinkO3B(parseInt(e.target.value))}/>
                                        
                                        <label className="form-control-label"> Link CD: </label>
                                        <input type="number" className="form-control" value={linkCD} onChange={(e)=>setLinkCD(parseInt(e.target.value))}/>

                                        <label className="form-control-label"> Link O4D: </label>
                                        <input type="number" className="form-control" value={linkO4D} onChange={(e)=>setLinkO4D(parseInt(e.target.value))}/>

                                        <input type="checkbox" className="form-check-input" id="flexCheckDefault" onChange={(e)=>setCrossLinkage(e.target.checked)}/>
                                        <label className="form-checkbox-label" htmlFor="flexCheckDefault">Mecanismo cruzado: </label>
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>
                <div className='col-lg-6 col-sm-12'>
                    <div className="shadow p-3 mb-5 bg-body rounded center h-100">
                        <MechanismGraph 
                            label = 'Watts Six Bar Linkage'
                            pointLabels = {['O2', 'A', 'B', 'O3', 'O4', 'O2', 'O3', 'O2', 'C', 'A', 'C', 'D', 'O4']}
                            data = {[
                                { x: X_O2, y:Y_O2 },
                                { x: Xa,  y:Ya},
                                { x: Xb,  y:Yb},
                                { x: X_O3, y:Y_O3},
                                { x: X_O4, y:Y_O4},
                                { x: X_O2, y:Y_O2},
                                { x: X_O3, y:Y_O3},
                                { x: X_O2, y:Y_O2},
                                { x: Xc, y:Yc},
                                { x: Xa, y:Ya},
                                { x: Xc, y:Yc},
                                { x: Xd, y:Yd},
                                { x: X_O4, y:Y_O4},
                            ]}
                            borderColor='rgb(17, 17, 218)'
                        />
                    </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                    <div className="shadow p-3 mb-5 bg-body rounded h-100">
                        <h2>outputs</h2>
                        <div>
                            <span> Theta3: {theta3.toFixed(1)}</span>
                        </div>
                        <div>
                            <span> Theta4: {theta4.toFixed(1)}</span>
                        </div>
                        <div>
                            <span> Theta5: {theta5.toFixed(1)}</span>
                        </div>
                        <div>
                            <span> Theta6: {theta6.toFixed(1)}</span>
                        </div>
                        <div>
                            <span>JuntaA = X:{Xa.toFixed(2)}, Y:{Ya.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaA_d = X_d:{Xa_d.toFixed(2)}, Y_d:{Ya_d.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaA_dd = X_dd:{Xa_dd.toFixed(2)}, Y_dd:{Ya_dd.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaB = X:{Xb.toFixed(2)}, Y:{Yb.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaB_d = X_d:{Xb_d.toFixed(2)}, Y_d:{Yb_dd.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaB_dd = X_dd:{Xb_dd.toFixed(2)}, Y_dd:{Yb_dd.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaC = X:{Xc.toFixed(2)}, Y:{Yc.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaC_d = X_d:{Xc_d.toFixed(2)}, Y_d:{Yc_dd.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaC_dd = X_dd:{Xc_dd.toFixed(2)}, Y_dd:{Yc_dd.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaD = X:{Xd.toFixed(2)}, Y:{Yd.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaD_d = X_d:{Xd_d.toFixed(2)}, Y_d:{Yd_dd.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaD_dd = X_dd:{Xd_dd.toFixed(2)}, Y_dd:{Yd_dd.toFixed(2)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


