import React from 'react'

import {Link} from 'react-router-dom'

import './Menu.css'
import logo from '../../img/logo.svg'


function Menu(){
    return(
        <nav className='navbar navbar-expand-lg navbar-light bg-light'>
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <img src={logo} alt="" width="30" height="30" className="d-inline-block align-text-top"/>
                    <span className="Logo">CORPORIGIDO.<span className="Red">X</span><span className="Green">Y</span><span className="Blue">Z</span></span>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className='navbar-nav'>
                        <li className='nav-item'>
                            <Link className='nav-link' to="/">home</Link>
                        </li>
                        <li className='nav-item'>
                            <Link className='nav-link' to="/about">sobre</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="/analysis-2d" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">analise 2d</Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><Link className="dropdown-item" to="/analysis-2d/four-bar-linkage">four-bar linkage</Link></li>
                                <li><Link className="dropdown-item" to="/analysis-2d/slider-crank-linkage">slider-crank linkage</Link></li>
                                {/* TODO <li><Link className="dropdown-item" to="/analysis-2d/stephenson-six-bar-linkage">Stephenson six-bar linkage</Link></li> */}
                                <li><Link className="dropdown-item" to="/analysis-2d/watts-six-bar-linkage">watts six-bar linkage</Link></li>
                            </ul>
                        </li>
                        {/* TODO <li className='nav-item'>
                            <Link className='nav-link' to="/docs">docs</Link>
                        </li>*/}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Menu