import React from 'react'

import CorporigidoLogo from '../components/layout/CorporigidoLogo'

import fourbarlinkage from '../img/four-bar.png'
import slidercranklinkage from '../img/slider-crank.png'
import watts6barlinkage from '../img/watts6bar.png'

function About(){
    return(
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <h1>sobre</h1>
                    <p>
                        <CorporigidoLogo /> é um aplicativo web para auxiliar alunos de engenharia a fazer análises de mecanismos. Tenho como objetivo prover um ambiente online com diversos mecanismos já pré-modelados, onde o aluno consiga interagir e coletar as respostas necessárias. Além disso, estou desenvolvendo uma documentação para poder entender a técnica de modelagem utilizada, e uma API distribuída sob licença MIT, para qualquer um pode utilizar para fazer seus próprios modelos, que pode ser encontrada no meu GitHub <a href='https://github.com/felipeaamacedo/design-of-machinery'>https://github.com/felipeaamacedo/design-of-machinery</a>
                    </p>

                    <p>
                        O do app foi inspirado pelo primeiro software que eu fiz no início de seu mestrado em 2016, que o nome era exatamente corporigido. Meu orientador na época passava como exercício para aprender programação orientada a objetos, programação de software usando bibliotecas de game 3D.
                    </p>
                    <p>
                        Hoje o <CorporigidoLogo /> não fornece um ambiente 3D para análise cinemática, mas é um dos meus próximos objetivos, então me aguardem! Entretanto, como vocês podem ver abaixo, já podemos simular mecanismos como: o quatro-barras, o biela-manivela-pistão, e um mecanismo de 6 barras watts. 
                        {/* TODO que nesse caso aproveitei e modelei para simular uma suspensão duplo A, apesar de precisar de um pouquinho de imaginação.*/}
                    </p>
                </div>

                <br></br>

            </div>
            <div className="row">
                <div className="col-lg-4 col-md-12">
                    <img src={fourbarlinkage} alt="" width='400px'/>
                </div>
                <div className="col-lg-4 col-md-12">
                    <img src={slidercranklinkage} alt="" width='400px'/>
                </div>
                <div className="col-lg-4 col-md-12">
                    <img src={watts6barlinkage} alt="" width='400px'/>
                </div>
            </div>
        </div>
    )
}

export default About