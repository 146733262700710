import React, { useState } from 'react'

import {RR_Fwd, RR_Inv} from './kin-pair'
import MechanismGraph from '../MechanismGraph';



function FourBarLinkageCalc(){
    const [theta2, setTheta2] = useState<number>(30)
    const [theta2Rad_d, setTheta2Rad_d] = useState<number>(0)
    const [theta2Rad_dd, setTheta2Rad_dd] = useState<number>(0)
    const [linkA, setLinkA] = useState<number>(2)
    const [linkB, setLinkB] = useState<number>(7)
    const [linkC, setLinkC] = useState<number>(9)
    const [linkD, setLinkD] = useState<number>(6)
    const [crossLinkage, setCrossLinkage] = useState<boolean>(false)

    let X_O2:number=0 
    let X_O4:number=6 
    let Y_O2:number=0 
    let Y_O4:number=0 


    //KINEMATICS CALCULATION
    let kinpar1:number[] = RR_Fwd(0,linkA, X_O2, Y_O2,0,theta2,0,theta2Rad_d,0,theta2Rad_dd)
    let Xa:number = kinpar1[0]
    let Ya:number = kinpar1[1]
    let Xa_d:number = kinpar1[2]
    let Ya_d:number = kinpar1[3]
    let Xa_dd:number = kinpar1[4]
    let Ya_dd:number = kinpar1[5]

    let kinpar2:number[]=RR_Inv(linkC,linkB,Xa,Ya,X_O4,Y_O4,kinpar1[2],kinpar1[3],kinpar1[4],kinpar1[5],crossLinkage)
    let Xb:number = kinpar2[0]
    let Yb:number = kinpar2[1]
    let Xb_d:number = kinpar2[2]
    let Yb_d:number = kinpar2[3]
    let Xb_dd:number = kinpar2[4]
    let Yb_dd:number = kinpar2[5]

    let theta3:number = 180 + kinpar2[6]
    let theta4:number = kinpar2[7]

    return(
        <div className="container">
            <div>
                <h1>modelagem mecanismo 4 barras</h1>
            </div>
            <div className="row">
                <div className="col-lg-3 col-sm-12">
                    <div className="shadow p-3 mb-5 bg-body rounded h-100">
                        <h2>inputs</h2>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Variáveis</button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <label className="form-control-label">Theta2: </label>
                                        <input type="number" className="form-control" value={theta2} onChange={(e)=>setTheta2(parseInt(e.target.value))}/>

                                        <label className="form-control-label">Theta2_d: </label>
                                        <input type="number" className="form-control" value={theta2Rad_d} onChange={(e)=>setTheta2Rad_d(parseInt(e.target.value))}/>


                                        <label className="form-control-label">Theta2_dd: </label>
                                        <input type="number" className="form-control" value={theta2Rad_dd} onChange={(e)=>setTheta2Rad_dd(parseInt(e.target.value))}/>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">Constantes</button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                            <label className="form-control-label">Link A: </label>
                                            <input type="number" className="form-control" value={linkA} onChange={(e)=>setLinkA(parseInt(e.target.value))}/>
                                            <label className="form-control-label">Link B: </label>
                                            <input type="number" className="form-control" value={linkB} onChange={(e)=>setLinkB(parseInt(e.target.value))}/>
                                            <label className="form-control-label">Link C: </label>
                                            <input type="number" className="form-control" value={linkC} onChange={(e)=>setLinkC(parseInt(e.target.value))}/>
                                            <label className="form-control-label">Link D: </label>
                                            <input type="number" className="form-control" value={linkD} onChange={(e)=>setLinkD(parseInt(e.target.value))}/>
                                            <input type="checkbox" className="form-check-input" id="flexCheckDefault" onChange={(e)=>setCrossLinkage(e.target.checked)}/>
                                            <label className="form-checkbox-label" htmlFor="flexCheckDefault">Mecanismo cruzado: </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-sm-12'>
                    <div className="shadow p-3 mb-5 bg-body rounded center h-100">
                        <MechanismGraph 
                            label = 'Quatro barras'
                            pointLabels = {['O2', 'A', 'B', 'O4']}
                            data = {[
                                { x: X_O2, y:Y_O2 },
                                { x: Xa,  y:Ya},
                                { x: Xb,  y:Yb},
                                { x: X_O4, y:Y_O4},
                            ]}
                            borderColor = 'rgb(204, 16, 16)'
                        />
                    </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                    <div className="shadow p-3 mb-5 bg-body rounded h-100">
                        <h2>outputs</h2>
                        <div>
                            <span> Theta3: {theta3.toFixed(1)}</span>
                        </div>
                        <div>
                            <span> Theta4: {theta4.toFixed(1)}</span>
                        </div>
                        <div>
                            <span>JuntaA = X:{Xa.toFixed(2)}, Y:{Ya.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaA_d = X_d:{Xa_d.toFixed(2)}, Y_d:{Ya_d.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaA_dd = X_dd:{Xa_dd.toFixed(2)}, Y_dd:{Ya_dd.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaB = X:{Xb.toFixed(2)}, Y:{Yb.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaB_d = X_d:{Xb_d.toFixed(2)}, Y_d:{Yb_dd.toFixed(2)}</span>
                        </div>
                        <div>
                            <span>JuntaB_dd = X_dd:{Xb_dd.toFixed(2)}, Y_dd:{Yb_dd.toFixed(2)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default FourBarLinkageCalc