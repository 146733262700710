import React from 'react'

import { Switch, Route } from 'react-router-dom'

import Home from '../../views/Home'
import About from '../../views/About'
import Analysis2D from '../../views/Analysis2D'
import Documentation from '../../views/Documentation'

function Content(){
    return(
        <div>
            <Switch>
                <Route path="/about">
                    <About />
                </Route>
                <Route path="/analysis-2d">
                    <Analysis2D />
                </Route>
                <Route path="/docs">
                    <Documentation />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </div>
    )
}

export default Content