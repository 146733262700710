import React, { useState } from 'react'
import {BrowserRouter, Route, Switch, Link, useRouteMatch } from 'react-router-dom'

import FourBarLinkageCalc from '../components/calculation/FourBarLinkageCalc'
import SliderCrankLinkageCalc from '../components/calculation/SliderCrankLinkageCalc'
import StephensonSixBarLinkageCalc from '../components/calculation/WattsSixBarLinkageCalc'
import WattsSixBarLinkageCalc from '../components/calculation/WattsSixBarLinkageCalc'



function Analysis2D(){
    let match = useRouteMatch()
    return(
        <div>
            <div>
                <Switch>
                    <Route path={`${match.path}/four-bar-linkage`}>
                        <FourBarLinkageCalc />
                    </Route>
                    <Route path={`${match.path}/slider-crank-linkage`}>
                        <SliderCrankLinkageCalc />
                    </Route>
                    <Route path={`${match.path}/stephenson-six-bar-linkage`}>
                        <StephensonSixBarLinkageCalc />
                    </Route>
                    <Route path={`${match.path}/watts-six-bar-linkage`}>
                        <WattsSixBarLinkageCalc />
                    </Route>
                </Switch>
            </div>
        </div>
    )
}

export default Analysis2D